import axios from "axios";
import { useState } from 'react';
import './App.css';
import rules from "./rules/rules.json";
import Select, { components } from "react-select";
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Spinner from 'react-bootstrap/Spinner';
import { useMediaQuery } from "@uidotdev/usehooks";
import classNames from "classnames";
import logo from "./perspective-dice-six-faces-random_38559.png";
import Alert from "react-bootstrap/Alert";

const REACT_APP_API_HOST = process.env.REACT_APP_API_HOST || "";

function createCustomReactSelectOption(Base) {
  return ({ children, ...props }) => {
    console.log(Base);
    console.log(props);
    return (
      <Base {...props}>
        <div className="p-2">
          <img src={"/images/" + props.data.value + ".webp"} width="32" height="32" style={{ marginRight: "0.5rem" }} /> {props.data.label}
        </div>
      </Base>
    );
  }
}

function App() {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const options = Object.entries(rules).map(([gameId, game]) => {
    return {
      label: game["name"],
      value: gameId,
    };
  });

  const [game, setGame] = useState(options[0]);
  const [question, setQuestion] = useState("");
  const [askInProgress, setAskInProgress] = useState(false);

  const [conversation, setConversation] = useState([]);

  async function sendQuestion(q) {
    const newConversation = [...conversation, q];
    setConversation(newConversation);

    setAskInProgress(true);
    try {
      const response = await axios.post(REACT_APP_API_HOST + "/v1/ask", {
        game_id: game.value,
        conversation: newConversation,
      });

      setConversation([...newConversation, response.data["answer"]]);
    } finally {
      setAskInProgress(false);
    }

  }

  function onGameChange(game) {
    setGame(game);
    setConversation([]);
  }

  async function onSubmitClick() {
    setQuestion("");

    await sendQuestion(question);
  }

  async function onExampleQuestionClick(question) {
    await sendQuestion(question);
  }

  return (
    <div className="container">
      <div className="app-container">
        <div style={{ flexGrow: 1, overflowY: "auto" }} className="pt-4">
          <h2 className="mb-3"><img width={48} src={logo} style={{ marginRight: "0.5rem" }} />RulesGPT</h2>
          <hr />
          {conversation.map((text, i) => (
            <Card key={i} text={i % 2 === 1 ? "light" : null} bg={i % 2 === 1 ? "primary" : null} className={classNames("mb-2", {
              "ms-5": i % 2 === 0 && !isSmallDevice, "me-5": i % 2 === 1 && !isSmallDevice,
              "ms-3": i % 2 === 0 && isSmallDevice, "me-3": i % 2 === 1 && isSmallDevice
            })}>
              <Card.Body>
                {text}
              </Card.Body>
            </Card>
          ))}
          {askInProgress && (
            <Card bg="primary" className={classNames("mb-2", {
              "me-5": !isSmallDevice,
              "me-3": isSmallDevice,
            })}>
              <Card.Body>
                <Spinner variant="light" />
              </Card.Body>
            </Card>
          )}
        </div>
        {conversation.length == 0 && rules[game.value].exampleQuestions && rules[game.value].exampleQuestions.length > 0 && (
          <Alert variant="primary" className="mb-0">
            Example questions:
            <ul className="mb-0">
              {rules[game.value].exampleQuestions.map(q => <li><a href="#" onClick={() => onExampleQuestionClick(q)}>{q}</a></li>)}
            </ul>
          </Alert>
        )}
        <Form className={classNames("mt-3", { "mb-3": isSmallDevice, "mb-5": !isSmallDevice })}>
          <Select value={game} options={options} onChange={e => onGameChange(e)} className="mb-2"
            components={{
              Option: createCustomReactSelectOption(components.Option),
              SingleValue: createCustomReactSelectOption(components.SingleValue),
            }}
            menuPlacement="auto"
          />
          <InputGroup>
            <Form.Control
              placeholder="Ask your question"
              size="lg"
              value={question}
              onChange={e => setQuestion(e.target.value)}
            />
            <Button disabled={askInProgress} type="submit" style={{ zIndex: 0 }} onClick={onSubmitClick}>Ask</Button>
          </InputGroup>
        </Form>
      </div>
    </div >
  );
}

export default App;
